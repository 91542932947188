import { userService } from '../services';
import {alertActions, userActions} from './';
import { history } from '../helpers';
import { globals } from './';
import * as toast from "../components/utility/toast";
// eslint-disable-next-line no-unused-vars
import React from "react";
import sendFirebaseToken from "../helpers/sendFirebaseToken";
import cookie from "react-cookies";

export const authActions = {
    register,
    login,
    sendCode,
    resendCode,
    forgotPassword,
    forgotResendCode,
    forgotSendCode,
    resetPassword,
    logOut,
    verifyEmail,
    logOutKiosk
};

function logOut(path = "/") {
    return dispatch => {
        let cookieFirebase = cookie.load('HDeviceToken');
        if(cookieFirebase){
            dispatch(userActions.subscribeFirebase({firebase_token: ''})).then(()=>{
                localStorage.removeItem('token');
                localStorage.setItem('LoginStatus', 'false');
                localStorage.removeItem('mobileModalPromo');
                history.push(path)
                window.location.reload();
            })
        }
        else{
            localStorage.removeItem('token');
            localStorage.removeItem('mobileModalPromo');
            localStorage.setItem('LoginStatus', 'false');
            history.push(path)
            window.location.reload();
        }

    }
}
function logOutKiosk(path = "/") {
    return dispatch => {
        localStorage.removeItem('token');
        localStorage.setItem('LoginStatus', 'false');
        history.push(path)
        dispatch(globals.cartCount());
        toast.success({text:'Thank you for your purchase. Please  wait for the notification to come to the checkout'})
    }
}

function register(user) {
    return dispatch => {
        userService.register('/auth/register',user)
            .then(
                () => {
                    sendFirebaseToken( dispatch )
                    //history.push('/phone-verification');
                    history.push('/');
                    dispatch(globals.userInfo());
                },
                error => {
                    if(error.errorCode !== 403){
                        dispatch(alertActions.verify_error(error.errors));
                    }

                }
            );
    };

}

function login(user, from) {
    return dispatch => {
        userService.login('/auth/login', user)
            .then(
                () => {
                    dispatch(globals.userInfo());
                    if (from.pathname === '/checkout' ){
                        history.push('/checkout');
                    } else {
                        history.push('/');
                    }
                    sendFirebaseToken( dispatch )
                }
            );
    };

}

function sendCode(user) {
    return dispatch => {
        userService.PostInfo('/auth/otp', user)
            .then(
                data => {
                    dispatch(globals.userInfo());
                    history.push('/');
                    toast.success({ text: data.message })
                }
            );
    };
}
function resendCode(user) {
    return dispatch => {
        userService.PostInfo('/auth/resend-otp',user)
            .then(
                data => {
                    toast.success({ text: data.message })
                }
            );
    };
}

function verifyEmail(data) {
    return dispatch => {
        dispatch(requestLoader());
        userService.PostInfo('/auth/confirm-email',data)
            .then(
                data => {
                    dispatch(globals.userInfo());
                    dispatch(successLoader());
                },
                error =>{
                    dispatch(failureLoader());
                    history.push('/')
                }
            );
    };
    function requestLoader() { return { type: 'REQUEST' } }
    function successLoader() { return { type: 'SUCCESS' } }
    function failureLoader() { return { type: 'FAILURE' } }
}

function forgotPassword(user) {
    return dispatch => {
        userService.PostInfo('/auth/forgot-password-by-code',user)
            .then(
                () => {
                    history.push('/forgot-verification');
                },
                error => {
                    if(error && error.errors && error.errors.length && error.errorCode !== 403){
                        dispatch(alertActions.verify_error(error.errors));
                    }
                }
            )
    };
}
function forgotResendCode(user) {
    return dispatch => {
        userService.PostInfo('/auth/forgot-password-by-code',user)
            .then(
                result => {
                    toast.success({ text: result.message })
                },
                error => {
                    if(error && error.errors && error.errors.length && error.errorCode !== 403){
                        dispatch(alertActions.verify_error(error.errors));
                    }
                }
            )
    };
}
function forgotSendCode(user) {
    return dispatch => {
        userService.PostInfo('/auth/check-verification-code',user)
            .then(
                result => {
                    history.push(`/reset-password/${result.token}`);
                },
                error => {
                    if(error && error.errors && error.errors.length && error.errorCode !== 403){
                        dispatch(alertActions.verify_error(error.errors));
                    }
                }
            )
    };
}
function resetPassword(data) {
    return () => {
        userService.PostInfo(`/auth/reset-password/${data.token}`,data)
            .then(
                result => {
                    localStorage.removeItem('phone');
                    history.push('/login')
                    toast.success({ text: result.message })
                }
            );
    };
}
